<template>
  <v-dialog max-width="500px" v-model="show" scrollable>
    <v-card class="pa-4" style="border-radius: 6px">
      <v-card-text class="pa-0">
        <v-row
          id="share-network-list"
        >
          <template
              v-for="network in networks"
          >
            <v-col
            class="pa-0"
              :key="network.network"
            >
              <ShareNetwork
                :network="network.network"
                :style="{backgroundColor: network.color}"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
              >
                <i :class="network.icon"></i>
                <span>{{ network.name }}</span>
              </ShareNetwork>
            </v-col>
          </template>
          <v-col cols="12" class="text-center">
            <v-btn @click="close" large outlined>
              <!-- {{ $t("ok") }} -->
              CLOSE
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
    }),
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    sharing () {
      return {
        url: `${window.location.href}`,
        title: this.venueData.name || this.venueData.title,
        description: this.venueData.shortDescription
        // quote: 'The hot reload is so fast it\'s near instant. - Evan You',
        // hashtags: 'vuejs,vite,javascript',
        // twitterUser: 'youyuxi'
      }
    }
  },
  data () {
    return {
      networks: [
        { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
        { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' }
      ]
    }
  },
  created () {
  },
  methods: {
    close () {
      this.show = false
    }
  },
  props: {
    value: {
      type: Boolean
    },
    venueData: {
      required: true,
      type: Object
    }
  }
}
</script>
<style scoped>
#share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}

a[class^="share-network-"] {
  flex: none;
  color: #FFFFFF;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
}

a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 1 auto;
}

a[class^="share-network-"] span {
  padding: 0 10px;
  flex: 1 1;
  font-weight: 500;
}

a.share-network-twitter {
  background-color: #1da1f2;
}

a.share-network-fakeblock {
  background-color: #41b883;
}
</style>
