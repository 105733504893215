<template>
  <v-container class="home-container">
    <!-- start spiner --->
    <v-row align="center" class="ma-3" justify="center" v-if="loading">
      <v-progress-circular indeterminate color="primary">
      </v-progress-circular>
    </v-row>
    <!-- end -->
    <VenuesList v-if="venuesData && venuesData.length" :venues-data="venuesData"
      @select-venue="($evt) => $emit('select-venue', $evt)"
    />
    <div class="text-right actions">
      <v-btn
        color="kbBlue"
        class="actions-btn"
        fab
        large
        @click="gotoFilters()"
      >
        <img
          src="https://storage.googleapis.com/keybe/kb.live/img/main_layout-filter.svg?DA=3"
          alt="Filter"
          width="75px"
        >
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import VenuesList from '@/pages/Home/VenuesList'
import windowSize from '../../mixins/windowSize'
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: { VenuesList },
  mixins: [windowSize],
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      eventsJsLoaded: (state) => state.events.eventsJsLoaded,
      loading: state => state.events.loadingEvents
    })
  },
  methods: {
    gotoFilters () {
      this.$router.push({ name: 'Filters' })
    }
  },
  props: {
    venuesData: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="css" scoped>
.home-container {
  background-color: var(--v-kbGray-base);
  width: 100%;
  padding: 20px;
}
.actions {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.actions-btn {
  width: 75px;
  height: 75px;
  padding: 2.5em;
}
@media only screen and (min-width: 768px) {
  .actions {
    right: calc((100vw - 768px) / 2 + 10px);
  }
}
</style>
