import { render, staticRenderFns } from "./VenueEventOrPlace.vue?vue&type=template&id=4bff8c96&scoped=true&"
import script from "./VenueEventOrPlace.vue?vue&type=script&lang=js&"
export * from "./VenueEventOrPlace.vue?vue&type=script&lang=js&"
import style0 from "./VenueEventOrPlace.vue?vue&type=style&index=0&id=4bff8c96&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.3_b61fd53126d8a76b25cc5244d186282f/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bff8c96",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.6.0_9053c641aa08e2db0287c4c8a8df6e99/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VImg,VRow})
