<template>
  <div>
    <template v-if="$vuetify.breakpoint.width < 768">
      <hooper class="kb-slider-container"
              :centerMode="true"
              :items-to-show="1"
              :mouse-drag="false"
              pagination="no"
              :vertical="true">
        <slide v-for="(venue, idx) in venues"
               :key="idx"
               :index="idx"
               style="overflow-x: hidden!important;">
          <VenueEventOrPlace
            class="py-3"
            :venue-item="venue"
            :venue-idx="idx"
            @selectVenue="($evt) => $emit('select-venue', $evt)"
          />
        </slide>
      </hooper>
    </template>

    <template v-else>
      <VenueEventOrPlace
        v-for="(venue, idx) in venues"
        :key="idx"
        :venue-item="venue"
        :venue-idx="idx"
        @selectVenue="($evt) => $emit('select-venue', $evt)"
      />
    </template>
  </div>
</template>
<script>
import VenueEventOrPlace from './VenueEventOrPlace'
import { Hooper, Slide } from 'hooper'
export default {
  name: 'VenuesList',
  components: {
    Hooper,
    Slide,
    VenueEventOrPlace
  },
  data () {
    return {}
  },
  props: {
    venuesData: {
      type: Array,
      required: true
    }
  },
  computed: {
    venues () {
      return this.shuffle(this.venuesData)
    }
  },
  methods: {
    /* mezclar array */
    shuffle (arr) {
      let j
      let temp
      for (let i = arr.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1))
        temp = arr[i]
        arr[i] = arr[j]
        arr[j] = temp
      }
      return arr
    }
  }
}
</script>
<style scoped>
::v-deep ul, ul.hooper-track {
  list-style: none!important;
  padding-left: 0!important;
}
.kb-slider-container {
  height: calc(100vh - 72px - 21px);
  overflow-y: hidden;
}
</style>
