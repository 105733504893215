<template>
  <div>
    <router-view
      @show-prev-venue="$emit('show-prev-venue')"
      @show-next-venue="$emit('show-next-venue')"
    />
    <ShareDialog v-model="showShareDialog" :venue-data="venueData" />
    <template
      v-if="selectedVenue === 'Place' && countCartProducts"
    >
      <v-bottom-navigation
        class="bottom-bar-navigation"
        style="cursor: pointer;"
        :background-color="$vuetify.theme.dark ? '#000000' : '#8898aa'"
        grow
        fixed
        dark
        :height="88"
      >
        <div
          @click="goToProductsCart"
          class="bottom-bar-navigation-products-cart d-flex align-center"
        >
          <v-btn
            width="20px"
          >
            <img
              src="https://storage.googleapis.com/keybe/kb.live/img/bottom_bar_products_cart-receipt.svg"
            >
          </v-btn>
          <h3 class="text-uppercase">
            {{ countCartProducts }} {{ $tc('products', countCartProducts) }}
          </h3>
          <v-spacer />
          <h3>
            $ {{ totalPriceCartProducts }}
          </h3>
          <v-btn
            color="kbBlue"
            text
            width="20px"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-bottom-navigation>
    </template>
    <template v-else>
      <v-bottom-navigation
        color="yellow"
        class="bottom-bar-navigation"
        :background-color="$vuetify.theme.dark ? '#000000' : '#8898aa'"
        grow
        fixed
        dark
        :height="88"
      >
        <template v-for="(actionBtn, idx) in floatingActionButtons">
          <v-btn
            :key="idx" v-if="actionBtn.key === 'planner'"
            @click="goToReservations"
            class="pb-1"
          >
            <span class="d-block" style="margin-top: 10px">Book</span>
            <img
              src="https://storage.googleapis.com/keybe/kb.live/img/place_action_buttons_calendar.svg"
              alt="Book"
            >
          </v-btn>
          <v-btn
            :key="idx" v-if="actionBtn.key === 'cart'"
            @click="confirmEventPurchase"
            class="pb-1"
          >
            <span class="d-block" style="margin-top: 10px">{{ $t('entrances') }}</span>
            <!-- <v-icon dark>mdi-ticket-confirmation-outline</v-icon> -->
            <img
              width="32"
              height="32"
              src="https://storage.googleapis.com/keybe-live/img/ticket.svg"
              alt="Entradas"
            >
          </v-btn>
          <v-btn
            :key="idx" v-if="actionBtn.key === 'message'"
            @click="openChat"
            class="pb-1"
          >
            <span class="d-block" style="margin-top: 10px">{{ $t("message") }}</span>
            <img
              src="https://storage.googleapis.com/keybe-live/img/kb-chat.svg"
              alt="Message"
            >

          </v-btn>

          <v-btn
            :key="idx" v-if="actionBtn.key === 'share'"
            @click="openShareDialog"
            class="pb-1"
          >
            <span class="d-block" style="margin-top: 10px">{{ $t('share') }}</span>
            <img
              src="https://storage.googleapis.com/keybe-live/img/share.svg"
              alt="Share"
            >
          </v-btn>

          <v-btn
            :key="idx" v-if="actionBtn.key === 'nearby'"
            class="pb-1"
          >
            <span class="d-block" style="margin-top: 10px">Nearby</span>
            <img
              src="https://storage.googleapis.com/keybe/kb.live/img/bottom_bar_location_pin.svg"
              alt="Nearby"
            >
          </v-btn>
          <!--
          <v-btn
            :key="idx" v-if="actionBtn.key === 'love'"
            @click="setLike(idx)"
            class="pb-1"
          >
            <span>Favorites</span>
            <template v-if="actionBtn.value">
              <v-icon dark>mdi-heart</v-icon>
            </template>
            <template v-else>
              <img
                src="https://storage.googleapis.com/keybe/kb.live/img/bottom_bar_heart.svg"
                alt="Favorites"
              >
            </template>

          </v-btn> -->
          <v-btn
            :key="idx" v-if="actionBtn.key === 'customize-filters'"
            @click="gotoFilters"
            class="pb-1"
          >
            <span class="d-block" style="margin-top: 10px">Customize</span>
            <img
              src="https://storage.googleapis.com/keybe-live/img/customize.svg"
              alt="Customize"
            >
          </v-btn>
        </template>
      </v-bottom-navigation>
    </template>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ShareDialog from '@/pages/Venues/VenueEvent/Dialogs/ShareDialog'

export default {
  components: { ShareDialog },
  computed: {
    ...mapState({
      selectedVenue: (state) => state.storedStates.selectedVenue,
      selectedEvent: (state) => state.events.selectedEvent,
      selectedPlace: (state) => state.places.selectedPlace,
      productCartItems: (state) => state.shoppingCart.productCartItems
    }),
    ...mapGetters('shoppingCart', [ 'countCartProducts' ]),
    ...mapGetters('shoppingCart', [ 'totalPriceCartProducts' ]),
    floatingActionButtons: {
      get () {
        return this.$store.state.tempStates.floatingActionButtons
      },
      set (value) {
        this.$store.commit('tempStates/SET_FLOATING_ACTION_BUTTONS', value)
      }
    },
    selectedVenueIndex: {
      get () {
        return this.$store.state.tempStates.selectedVenueIndex
      },
      set (value) {
        this.$store.commit('tempStates/SET_SELECTED_VENUE_INDEX', value)
      }
    },
    venueData () {
      if (this.selectedVenue === 'Event') {
        return this.selectedEvent
      } else if (this.selectedVenue === 'Place') {
        return this.selectedPlace
      }
      return {}
    }
  },
  data () {
    return {
      showShareDialog: false
    }
  },
  mounted () {
    this.checkForSelectedVenueIndex()
  },
  methods: {
    checkForSelectedVenueIndex (venueId) {
      // console.log('watcher venues data: len -> ' + this.venuesData.length)
      // this.selectedVenueIndex === undefined &&
      if (this.venuesData.length) {
        if (venueId === undefined) {
          venueId = this.$route.params.id
        }
        for (let i = 0; i < this.venuesData.length; i++) {
          if (this.venuesData[i].id === venueId || this.venuesData[i]._id === venueId) {
            this.selectedVenueIndex = i
            // console.log('found selectedVenueIndex!')
            // console.log(this.selectedVenueIndex)
            return
          }
        }
      }
    },
    setLike (idx) {
      this.floatingActionButtons[idx].value = !this.floatingActionButtons[idx].value
      console.log(this.floatingActionButtons)
    },
    openChat () {
      console.log('open chat!')
      window.keybe.openChat()
    },
    openShareDialog () {
      this.showShareDialog = true
    },
    async goToReservations () {
      this.$router.push({ name: 'Planner' })
    },
    confirmEventPurchase () {
      if (this.selectedVenue === 'Event') {
        this.$router.push({
          name: 'VenueEventPurchase',
          params: { id: this.$route.params.id }
        })
      } else if (this.selectedVenue === 'Place') {
        this.$router.push({ name: 'VenuePlacePurchase' })
      }
    },
    gotoFilters () {
      this.$router.push({ name: 'Filters' })
    },
    goToProductsCart () {
      this.$router.push({
        name: 'VenuePlacePurchase',
        params: { id: this.$route.params.id }
      })
    }
  },
  props: {
    venuesData: {
      type: Array,
      required: true
    }
  },
  watch: {
    venuesData: {
      deep: true,
      handler () {
        this.checkForSelectedVenueIndex()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .bottom-bar-navigation {
    width: 100%!important;
    .bottom-bar-navigation-products-cart {
      width: 100%;
    }
  }
  @media only screen and (min-width: 768px) {
    .bottom-bar-navigation {
      width: 768px!important;
      margin: 0 auto!important;
      left: calc((100% - 768px) / 2)!important;
    }
  }
</style>
