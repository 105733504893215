import { render, staticRenderFns } from "./VenuesList.vue?vue&type=template&id=706e067c&scoped=true&"
import script from "./VenuesList.vue?vue&type=script&lang=js&"
export * from "./VenuesList.vue?vue&type=script&lang=js&"
import style0 from "./VenuesList.vue?vue&type=style&index=0&id=706e067c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.3_b61fd53126d8a76b25cc5244d186282f/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "706e067c",
  null
  
)

export default component.exports