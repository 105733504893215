<template>
  <div @click="selectEventOrPlace()" style="cursor: pointer">
    <img class="venue-list-item-image" :src="this.venueItem.coverImage" />
    <v-row justify="center">
      <v-col cols="12" class="py-0">
        <v-row class="venue-event-info">
          <v-col cols="12" class="py-0">
            <v-row class="mx-0 flex-nowrap align-center">
              <v-img
                v-if="venueItem.component === 'Event'"
                :src="$vuetify.theme.dark ?
                'https://storage.googleapis.com/keybe/kb.live/img/venues_list_event.svg' :
                'https://storage.googleapis.com/keybe/kb.live/img/kb.live_img_venues_list_event_gray.svg'"
                max-width="30px"
                max-height="30px"
                class="mr-4"
              ></v-img>
              <v-img
                v-else-if="venueItem.component === 'Place'"
                :src="placeIcon"
                width="24px"
                max-width="24px"
                height="30px"
                max-height="30px"
                class="mr-4"
              ></v-img>
              <h1>{{ (venueItem.title || venueItem.name) | uppercase() }}</h1>
            </v-row>
          </v-col>

          <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;"
            v-if="venueItem.shortDescription || venueItem.description">
            <div class="venue-event-info_description">
              <p>{{ (venueItem.shortDescription || venueItem.description) }}</p>
            </div>
          </v-col>

          <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;"
            v-if="venueItem.placeLocation">
            <h3 @click="goTo(venueItem.placeLocationUrl)">
              {{ venueItem.placeLocation}}
            </h3>
          </v-col>

          <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;"
            v-if="venueItem.starts">
            <span :class="['yellow--text', 'font-weight-bold']">
              {{ venueItem.starts | moment('MMMM DD / hh a') }}
            </span>
          </v-col>

          <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;"
            v-if="venueItem.workingHours">
            <span :class="[isPlaceOpened ? 'yellow--text' : 'red--text', 'font-weight-bold']">
              {{ isPlaceOpened ? $t('open') : $t('closed') }}
            </span>
          </v-col>

          <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;">
            <v-row class="mx-0">
              <template v-if="venueItem.placeRating">
                <div class="mr-4">
                  <v-icon small v-for="i in venueItem.placeRating" :key="`filled-${i}`" color="yellow">mdi-star</v-icon>
                  <v-icon small v-for="i in (5 - venueItem.placeRating)" :key="`not-filled-${i}`">mdi-star</v-icon>
                </div>
              </template>
              <template v-if="venueItem.priceRating">
                <div>
                  <v-icon small v-for="i in venueItem.priceRating" :key="`filled-${i}`" color="yellow">mdi-currency-usd</v-icon>
                  <v-icon small v-for="i in (4 - venueItem.priceRating)" :key="`not-filled-${i}`">mdi-currency-usd</v-icon>
                </div>
              </template>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" style="padding-top: 5px; padding-bottom: 5px;">
            <span class="yellow--text font-weight-bold">
              0.25 mi
            </span>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import backgroundImage from '@/mixins/backgroundImage'
import moment from 'moment'
export default {
  name: 'VenueEvent',
  mixins: [backgroundImage],
  data () {
    return {}
  },
  props: {
    venueItem: {
      required: true,
      type: Object
    },
    venueIdx: {
      type: Number
    }
  },
  methods: {
    selectEventOrPlace () {
      this.$emit('selectVenue', { venueIndex: this.venueIdx, venueItem: this.venueItem })
    },
    goTo (url) {
      if (url) {
        window.open(url, '_blank')
      }
    }
  },
  computed: {
    imageProps () {
      return {
        url: this.venueItem.coverImage,
        backgroundSize: 'cover',
        width: '100%',
        height: this.$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 69px - 48px)' : 'calc(100vw - 40px)',
        opacity: '0',
        borderRadius: '2px'
      }
    },
    isPlaceOpened () {
      let isPlaceOpened
      try {
        if (this.venueItem.workingHours && this.venueItem.workingHours.days && Array.isArray(this.venueItem.workingHours.days) &&
          this.venueItem.workingHours.days.length) {
          // console.log('this.venueItem.workingHours')
          // console.log(this.venueItem.workingHours)
          let dateOffset = moment()
          let timeZone
          if (this.venueItem.timeZone) {
            timeZone = this.venueItem.timeZone.label
            // console.log('timeZone')
            // console.log(timeZone)
            dateOffset = dateOffset.tz(timeZone)
          }

          let dateOffsetDay = dateOffset.day()
          // console.log(moment().tz('Pacific/Niue').format('YYYY-MM-DD hh:mm:ss A Z'))
          // console.log(dateOffset.local().tz('Pacific/Niue').format('YYYY-MM-DD hh:mm:ss A Z'))
          // console.log(dateOffset.format('YYYY-MM-DD hh:mm:ss A Z'))
          // console.log(this.venueItem.workingHours.days[dateOffsetDay])
          if (!this.venueItem.workingHours.days[dateOffsetDay].isClosed) {
            if (Array.isArray(this.venueItem.workingHours.days[dateOffsetDay].schedules)) {
              if (!this.venueItem.workingHours.days[dateOffsetDay].schedules.length) isPlaceOpened = true
              for (let index = 0; index < this.venueItem.workingHours.days[dateOffsetDay].schedules.length; index++) {
                const schedule = this.venueItem.workingHours.days[dateOffsetDay].schedules[index]
                // console.log('schedule')
                // console.log(schedule)
                if (schedule.from && schedule.until) {
                  let from = moment(schedule.from, 'HH:mm')
                  let until = moment(schedule.until, 'HH:mm')
                  if (timeZone) {
                    from = from.tz(timeZone)
                    until = until.tz(timeZone)
                  }
                  // console.log(from.format('YYYY-MM-DD hh:mm:ss A Z'))
                  // console.log(until.format('YYYY-MM-DD hh:mm:ss A Z'))
                  // console.log(dateOffset.isBetween(from, until, 'minute'))
                  if (dateOffset.isBetween(from, until, 'minute')) isPlaceOpened = true
                }
              }
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
      return isPlaceOpened
    },
    placeIcon () {
      return this.$vuetify.theme.dark ? 'https://storage.googleapis.com/keybe/kb.live/img/venues_list_place.svg' : 'https://storage.googleapis.com/keybe/kb.live/img/venues_list_place_gray.png'
    }
  }
}
</script>

<style scoped lang="scss">
.venue-list-item-image {
  width: 100%;
  // max-height: 65vh;
}
.venue-event-info {
  margin-top: 20px;
  margin-bottom: 40px;
}
.venue-event-info h1 {
  font-weight: 800;
  font-size: 22px;
  text-align: left;
  letter-spacing: 2.16px;
}
.venue-event-info p {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
